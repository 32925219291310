export default {
  legacy: false,
  locale: 'hu',
  fallbackLocale: 'hu',
  numberFormats: {
    hu: {
      currency: {
        style: 'currency',
        currency: 'HUF',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
  } as Record<string, Record<string, Intl.NumberFormatOptions>>,
}
